/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import schoolHeader from "@static/images/shop/schoolHeader.jpeg"
import ShopContainer from "@shop/modules/ShopContainer"

const SchoolShop = ({ data, ...props }) => {
  return (
    <ShopContainer
      pageContent={{
        heading: "Show pride at school",
        image: schoolHeader,
        pillHeading: "What are you looking for?",
        pageTitle: "Shop School LGBTQIA+ Pride",
        headerText:
          "Support LGBTQIA+ students and champion inclusion everyday with flags, posters, and resources designed for Australian classrooms.",
        pageDescription: data.og.frontmatter.ogdescription,
      }}
      data={data}
      pathname={props.location.pathname}
      location={props.location}
      headerBg="darkPurple"
      headerColor="white"
    />
  )
}
export default SchoolShop

export const pageQuery = graphql`
  query {
    pride: shopifyCollection(handle: { eq: "pride" }) {
      ...ShopifyCollectionFragment
    }
    school: shopifyCollection(handle: { eq: "schools" }) {
      ...ShopifyCollectionFragment
    }
    workplace: shopifyCollection(handle: { eq: "workplace" }) {
      ...ShopifyCollectionFragment
    }
    og: markdownRemark(fileAbsolutePath: { glob: "**/pages/shop/index.md" }) {
      frontmatter {
        ogdescription
        accordion {
          ...Accordion
        }
        footerImg
      }
    }
  }
`
